var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    {
      staticClass: "realVideo",
      style: _vm.cardWidth[this.$store.state.vehicle.screenCoefficient]
    },
    [
      _c(
        "div",
        {
          staticClass: "box-card",
          style: _vm.cardWidth[this.$store.state.vehicle.screenCoefficient]
        },
        [
          _c(
            "div",
            { staticClass: "card-body", attrs: { id: "fullscreen" } },
            [
              _c("videoList", {
                ref: "flvPlayModule",
                attrs: { buttonValue: _vm.buttonValue },
                on: { handleCloseVideo: _vm.handleCloseVideo }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _c(
                "div",
                { staticClass: "buttons" },
                _vm._l(_vm.buttons, function(item) {
                  return _c(
                    "el-button",
                    {
                      key: item.key,
                      attrs: {
                        size: "small",
                        type: "text",
                        sort:
                          item.value == _vm.buttonValue ? "primary" : "grey",
                        title: item.value + "画"
                      },
                      on: {
                        click: function($event) {
                          return _vm.setPlayerNum(item.value, true)
                        }
                      }
                    },
                    [_c("i", { staticClass: "iconfont", class: item.key })]
                  )
                }),
                1
              ),
              _c(
                "div",
                { staticClass: "operationBtn" },
                [
                  _vm.$store.state.user.hdVideo == 1
                    ? _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: _vm.HDSwitchingStatus,
                            placeholder: "请选择",
                            cellclassname: "HDSelect"
                          },
                          on: { change: _vm.HDSwitching },
                          model: {
                            value: _vm.isHD,
                            callback: function($$v) {
                              _vm.isHD = $$v
                            },
                            expression: "isHD"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "标清", value: "标清" }
                          }),
                          _c("el-option", {
                            attrs: { label: "高清", value: "高清" }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        type: "text",
                        size: "small",
                        sort: "grey",
                        title: "停止"
                      },
                      on: {
                        click: function($event) {
                          return _vm.stopAllVideo(false)
                        }
                      }
                    },
                    [_c("i", { staticClass: "iconfont its_bianzu24" })]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "text",
                        sort: "grey",
                        title: "全屏"
                      },
                      on: { click: _vm.handleFullScreen }
                    },
                    [_c("i", { staticClass: "iconfont its_quanping" })]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "text", sort: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.exit()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "iconfont its_tuichu1" }),
                      _vm._v("退出")
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }