var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "monitoring" },
    [
      _c("el-row", [
        _c(
          "div",
          { staticClass: "expand-tree-box" },
          [
            _c("car-tree", {
              ref: "carTree",
              attrs: {
                showCheckBox: true,
                trailStep: _vm.trailStep,
                expand: true,
                bLoading: _vm.bLoading,
                carTreeHeight: _vm.CarTreeHeight,
                treeBoxHeight: _vm.treeBoxHeight,
                system: _vm.system
              },
              on: {
                handleToPlayVideo: _vm.handleToPlayVideo,
                handleHistoryVideo: _vm.handleHistoryVideo,
                stopRealVideoCard: _vm.stopRealVideoCard,
                stopHistioryVideoCard: _vm.stopHistioryVideoCard
              }
            })
          ],
          1
        )
      ]),
      _vm.showRealVideoCard
        ? _c("realVideo", {
            ref: "video",
            style: _vm.realVideoStyle,
            attrs: { id: "realVideoBox" },
            on: {
              stopAllVideo: _vm.stopAllRealVideo,
              exit: _vm.exitRealVideo,
              closePlay: _vm.closeRealPlay,
              closeDialog: _vm.closeRealVideo
            }
          })
        : _vm._e(),
      _vm.showhistoryVideoCard
        ? _c("historyVideo", {
            ref: "historyVideo",
            style: _vm.historyVideoStyle,
            attrs: { id: "historyVideoBox" },
            on: {
              handleChannelClick: _vm.handleChannelClick,
              stopAllVideo: _vm.stopAllHistoryVideo,
              close: _vm.closeAllHistoryVideo,
              closeDialog: _vm.closehistoryVideo,
              downLoadHistoryVideo: _vm.downLoadHistoryVideo
            }
          })
        : _vm._e(),
      _vm.dialogDownloadVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "下载历史视频",
                width: "500px",
                visible: _vm.dialogDownloadVisible,
                top: "18vh"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogDownloadVisible = $event
                }
              }
            },
            [
              _c("downloadLimit", {
                attrs: { params: _vm.dowloadParams },
                on: { submit: _vm.toHistoryDowload }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }