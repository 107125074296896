
//获取视频宽高
const fGetWidthHeight = function (maxWidth, maxHeight, videoNum, n) {
  var w = parseInt(maxWidth * 0.33);
  var h = parseInt(maxHeight * 0.33);
  if (videoNum == 1) {
    return { width: maxWidth, height: maxHeight };
  } else if (videoNum == 4) {
    if (n == 1) {
      w = parseInt(maxWidth * 0.5);
      h = parseInt(maxHeight * 0.5);
    } else if (n == 2) {
      var pre = fGetWidthHeight(maxWidth, maxHeight, videoNum, 1);
      w = maxWidth - pre.width;
      h = pre.height;
    } else {
      var pre = fGetWidthHeight(maxWidth, maxHeight, videoNum, n - 2);
      w = pre.width;
      h = maxHeight - pre.height;
    }
    return { width: w, height: h };
  } else if (videoNum == 6) {
    if (n == 1) {
      w = parseInt(maxWidth * 0.66);
      h = parseInt(maxHeight * 0.66);
    } else if (n == 2) {
      var pre = fGetWidthHeight(maxWidth, maxHeight, videoNum, 1);
      w = maxWidth - pre.width;
      h = parseInt(pre.height / 2);
    } else if (n == 3) {
      var pre = fGetWidthHeight(maxWidth, maxHeight, videoNum, 1);
      w = maxWidth - pre.width;
      h = pre.height - parseInt(pre.height / 2);
    } else if (n == 4) {
      var pre = fGetWidthHeight(maxWidth, maxHeight, videoNum, 1);
      w = parseInt(pre.width / 2);
      h = maxHeight - pre.height;
    } else if (n == 5) {
      var pre = fGetWidthHeight(maxWidth, maxHeight, videoNum, 1);
      w = pre.width - parseInt(pre.width / 2);
      h = maxHeight - pre.height;
    } else {
      var pre = fGetWidthHeight(maxWidth, maxHeight, videoNum, 1);
      w = maxWidth - pre.width;
      h = maxHeight - pre.height;
    }
    return { width: w, height: h };
  } else if (videoNum == 8) {
    if (n == 1) {
      w = parseInt(maxWidth * 0.75);
      h = parseInt(maxHeight * 0.75);
    } else if (n == 2 || n == 3) {
      var pre = fGetWidthHeight(maxWidth, maxHeight, videoNum, 1);
      w = maxWidth - pre.width;
      h = parseInt(pre.height / 3);
    } else if (n == 4) {
      var pre = fGetWidthHeight(maxWidth, maxHeight, videoNum, 1);
      w = maxWidth - pre.width;
      h = pre.height - 2 * parseInt(pre.height / 3);
    } else if (n == 5 || n == 6) {
      var pre = fGetWidthHeight(maxWidth, maxHeight, videoNum, 1);
      w = parseInt(pre.width / 3);
      h = maxHeight - pre.height;
    } else if (n == 7) {
      var pre = fGetWidthHeight(maxWidth, maxHeight, videoNum, 1);
      w = pre.width - 2 * parseInt(pre.width / 3);
      h = maxHeight - pre.height;
    } else {
      var pre = fGetWidthHeight(maxWidth, maxHeight, videoNum, 1);
      w = maxWidth - pre.width;
      h = maxHeight - pre.height;
    }
    return { width: w, height: h };
  } else if (videoNum == 10) {
    if (n == 1) {
      w = parseInt(maxWidth * 0.8);
      h = parseInt(maxHeight * 0.8);
    } else if (n == 2 || n == 3 || n == 4) {

      var pre = fGetWidthHeight(maxWidth, maxHeight, videoNum, 1);
      w = maxWidth - pre.width;
      h = parseInt(pre.height / 4);
    } else if (n == 5) {
      var pre = fGetWidthHeight(maxWidth, maxHeight, videoNum, 1);
      w = maxWidth - pre.width;
      h = pre.height - 3 * parseInt(pre.height / 4);
    } else if (n == 6 || n == 7 || n == 8 || n == 9 || n == 10) {
      var pre = fGetWidthHeight(maxWidth, maxHeight, videoNum, 1);
      w = maxWidth * 0.2;
      h = maxHeight - pre.height;
    } else {
      var pre = fGetWidthHeight(maxWidth, maxHeight, videoNum, 1);
      w = maxWidth - pre.width;
      h = maxHeight - pre.height;
    }
    return { width: w, height: h };
  } else if (videoNum == 9) {
    if (n == 3 || n == 6 || n == 9) {
      w = maxWidth - 2 * w;
    }
    if (n >= 7) {
      h = maxHeight - 2 * h;
    }
    return { width: w, height: h };
  } else if (videoNum == 16) {
    w = parseInt(maxWidth * 0.25);
    h = parseInt(maxHeight * 0.25);
    if (n == 4 || n == 8 || n == 12 || n == 16) {
      w = maxWidth - 3 * w;
    }
    if (n >= 13) {
      h = maxHeight - 3 * h;
    }
    return { width: w, height: h };
  } else if (videoNum == 25) {
    w = maxWidth * 0.2;
    h = maxHeight * 0.2;
    if (n == 5 || n == 10 || n == 15 || n == 20 || n == 25) {
      w = maxWidth - 4 * w;
    }
    if (n >= 21) {
      h = maxHeight - 4 * h;
    }
    return { width: w, height: h };
  } else if (videoNum == 36) {

    w = parseInt(maxWidth * 0.167);
    h = parseInt(maxHeight * 0.167);
    if (n == 6 || n == 12 || n == 18 || n == 24 || n == 30 || n == 36) {
      w = maxWidth - 5 * w;
    }
    if (n >= 31) {
      h = maxHeight - 5 * h;
    }
    return { width: w, height: h };

  } else if (videoNum == 49) {

    w = parseInt(maxWidth * 0.142);
    h = parseInt(maxHeight * 0.142);
    if (n == 7 || n == 14 || n == 21 || n == 28 || n == 35 || n == 42 || n == 47) {
      w = maxWidth - 6 * w;
    }
    if (n >= 31) {
      h = maxHeight - 6 * h;
    }
    return { width: w, height: h };

  } else if (videoNum == 64) {

    w = parseInt(maxWidth * 0.125);
    h = parseInt(maxHeight * 0.125);
    if (n == 8 || n == 16 || n == 24 || n == 32 || n == 40 || n == 48 || n == 56 || n == 64) {
      w = maxWidth - 7 * w;
    }
    if (n >= 31) {
      h = maxHeight - 7 * h;
    }
    return { width: w, height: h };

  } else {
    w = maxWidth * 0.2;
    h = maxHeight * 0.2;
    if (n == 5 || n == 10 || n == 15 || n == 20 || n == 25) {
      w = maxWidth - 4 * w;
    }
    if (n >= 21) {
      h = maxHeight - 4 * h;
    }
    return { width: maxWidth * 0.2, height: maxHeight * 0.2 };
  }
}


export default fGetWidthHeight;