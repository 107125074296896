<template>
  <div class="monitoring">
    <el-row>
      <div class="expand-tree-box">
        <!-- 树 -->
        <car-tree
          ref="carTree"
          :showCheckBox="true"
          :trailStep="trailStep"
          :expand="true"
          :bLoading="bLoading"
          :carTreeHeight="CarTreeHeight"
          :treeBoxHeight="treeBoxHeight"
          :system="system"
          @handleToPlayVideo="handleToPlayVideo"
          @handleHistoryVideo="handleHistoryVideo"
          @stopRealVideoCard="stopRealVideoCard"
          @stopHistioryVideoCard="stopHistioryVideoCard"
        ></car-tree>
      </div>
      
    </el-row>
    
    <!-- 实时视频 -->
    <realVideo
      id="realVideoBox"
      ref="video"
      @stopAllVideo="stopAllRealVideo"
      @exit="exitRealVideo"
      @closePlay="closeRealPlay"
      @closeDialog="closeRealVideo"
      :style="realVideoStyle"
      v-if="showRealVideoCard"
    />
    <!-- 历史视频 -->
    <historyVideo
      id="historyVideoBox"
      ref="historyVideo"
      @handleChannelClick="handleChannelClick"
      @stopAllVideo="stopAllHistoryVideo"
      @close="closeAllHistoryVideo"
      @closeDialog="closehistoryVideo"
      @downLoadHistoryVideo="downLoadHistoryVideo"
      :style="historyVideoStyle"
      v-if="showhistoryVideoCard"
    />
    <!--历史视频 下载时间限制 -->
    <el-dialog
      title="下载历史视频"
      width="500px"
      :visible.sync="dialogDownloadVisible"
      v-if="dialogDownloadVisible"
      top="18vh"
    >
      <downloadLimit :params="dowloadParams" @submit="toHistoryDowload" />
    </el-dialog>
  </div>
</template>

<script>
import { queryVehicleCardById } from '@/api/lib/refreshAlarm'

import carTree from '@/components/carTree/carTree.vue'
import realVideo from './realvideo'
import historyVideo from './historyVideo/historyVideo.vue'
import downloadLimit from './historyVideo/downloadLimit/downloadLimit.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'monitoring',
  components: {
    'car-tree': carTree, //车辆树
    realVideo,
    historyVideo,
    downloadLimit
  },
  data () {
    return {
      system: '0', //对应系统1为出租车
      /** 显示监控页面右边功能 0:地图 */
      trailStep: 0,      
      bLoading: false,
      reqData: [],
      showTab: true,
      // 数据看板联动样式修改
      TabHeight: '4vh',
      CarTreeHeight: '84vh',
      mapHeight: '84vh',
      treeBoxHeight: '72vh',
      showRealVideoCard: false, // 是否显示实时视频
      realVideoStyle: 'z-index:-10;',
      showhistoryVideoCard: false, // 是否显示历史视频
      historyVideoStyle: 'z-index: -10;',
      dialogDownloadVisible: false, // 下载时间选择显示
      dowloadParams: null,
      showTable: true,
      tableEnlarge: false,
      carDialogStyle: 'left:75%',
      menuActive: '0', //设置当前menu选中菜单
      carDetailTimer:null,
      setRefreshDailogStatus:false,
      
    }
  },
  computed: {
    ...mapGetters({
      isRefreshLive: 'getRefresh',
      nowMenuList: 'nowMenuList'

    }),
    trailTableHgight: function () {
      return {
        halfHeighTable: this.bigTrailTable === 'half',
        fullHeighTable: this.bigTrailTable === 'full',
        noneHeighTable: this.bigTrailTable === 'none'
      }
    },
    buttonPosition: function () {
      return `${parseInt(this.TabHeight) + 3}vh`
    }
  },
  methods: {
    /** 放大 |单击节点显示实时信息，指令信息 */
    enlarge () {
      this.showTab = true
      this.tableEnlarge = false
      this.TabHeight = '34vh'
      this.CarTreeHeight = '60vh'
      this.mapHeight = '60vh'
      this.treeBoxHeight = '44vh'
      this.showTable = true
    },
    /** tab切换 显示视频系统
     * @param {*} flag 是否显示 视频界面
     * @param {*} key 历史/实时
     */
    showVideoCard (flag, key) {
      if (flag) {
        switch (key) {
          case '1':
            if (this.showhistoryVideoCard === true)
              this.stopHistioryVideoCard(true)
            /** 如果正在播放视频，就让视频界面显示 */
            if (this.showRealVideoCard) {
              this.realVideoStyle = 'z-index:2000;'
              return
            }
            /** 初始化视频界面显示 */
            this.showRealVideoCard = true
            this.realVideoStyle = 'z-index:2000;'
            this.$nextTick(function () {
              this.$refs.video.showVideo()
            })
            break
          case '4':
            if (this.showRealVideoCard === true) this.stopRealVideoCard(true)
            /** 如果正在播放视频，就让视频界面显示 */
            if (this.showhistoryVideoCard) {
              this.historyVideoStyle = 'z-index:2000;'
              return
            }
            this.showhistoryVideoCard = true
            this.historyVideoStyle = 'z-index:2000;'
            this.$nextTick(function () {
              this.$refs.historyVideo.showVideo()
            })
            break

          default:
            break
        }
        this.setActiveIndex(key, true, key == '0')

        return
      }
      /** 如果正在播放视频，就让视频界面不显示 */
      if (this.showRealVideoCard) {
        this.closeRealVideo()
      }
      /** 如果正在播放视频，就让视频界面不显示 */
      if (this.showhistoryVideoCard) {
        this.closehistoryVideo()
      }

      this.setActiveIndex(key, true, key == '0')
    },
    /** 查询实时车辆视频 显示视频系统 */
    handleToPlayVideo (params, channel, flag) {
      let isInitHD =
        this.showRealVideoCard === false &&
        this.realVideoStyle === 'z-index:-10;'
      /** 还停留在历史视频界面 */
      if (this.showhistoryVideoCard === true) this.stopHistioryVideoCard(true)

      this.showRealVideoCard = true
      this.realVideoStyle = 'z-index:2000;'
      this.setActiveIndex('1')
      this.$nextTick(function () {
        this.$refs.video.handleToPlayVideo(params, channel, flag, isInitHD)
      })
    },
    /** 实时视频点击停止全部 */
    stopAllRealVideo () {
      if (this.$refs.carTree) this.$refs.carTree.stopAllVideo()
    },
    /** 实时视频点击退出视频 */
    exitRealVideo () {
      this.showRealVideoCard = false
      
      this.$refs.carTree.stopAllVideo()
    },
    /** 点击最小化实时视频 */
    closeRealVideo () {
      this.realVideoStyle = 'z-index:-10;'
    },

    /** 点击还原实时视频 */
    reductionRealVideo () {
      this.showRealVideoCard = true
      this.$nextTick(function () {
        this.realVideoStyle = 'z-index:2000;'
      })
    },
    /** 实时视频 超过播放时限 清除选中状态 */
    closeRealPlay (a, b) {
      this.$refs.carTree.closePlay(a, b)
    },
    /** 点击历史视频需要关闭正在播放的实时视频 */
    stopRealVideoCard (flag) {
      if (!flag) this.$refs.video.stopAllVideo(true)
      this.showRealVideoCard = false
      this.realVideoStyle = 'z-index:-10;'
    },
    /** ************************************************************************************************************************* */
    /** 查询历史车辆视频 显示历史视频系统  */
    handleHistoryVideo (d, n, flag) {
      /** 还停留在实时视频界面 */
      if (this.showRealVideoCard === true) this.stopRealVideoCard(true)
      this.showhistoryVideoCard = true
      this.historyVideoStyle = 'z-index:2000;'
      this.setActiveIndex('4')

      this.$nextTick(function () {
        this.$refs.historyVideo.handleClick(d, n, flag)
      })
    },

    /** 历史视频点击退出视频 */
    stopAllHistoryVideo () {
      this.showhistoryVideoCard = false
      
      this.historyVideoStyle = 'z-index:-10;'
      this.$nextTick(function () {
        if (this.$refs.carTree) this.$refs.carTree.stopAllHistoryVideo()
      })
    },
    /** 历史视频点击停止全部 */
    closeAllHistoryVideo () {
      if (this.$refs.carTree) this.$refs.carTree.stopAllHistoryVideo()
    },
    /** 点击最小化历史视频 */
    closehistoryVideo () {
      this.historyVideoStyle = 'z-index:-10;'
    },
    /** 点击还原历史视频 */
    reductionhistoryVideo () {
      this.showhistoryVideoCard = true
      this.$nextTick(function () {
        this.historyVideoStyle = 'z-index:2000;'
      })
    },
    /** 修改 下载历史视频参数 */
    downLoadHistoryVideo (params) {
      this.dialogDownloadVisible = true
      this.dowloadParams = params
    },
    /** 历史视频参数修改完成 */
    toHistoryDowload (params) {
      this.$refs.historyVideo.downLoadCheck(params)
      this.dialogDownloadVisible = false
    },
    /** 点击实时视频，需要去关闭正在播放的历史视频  */
    stopHistioryVideoCard (flag) {
      if (!flag) this.$refs.historyVideo.close(true)
      this.showhistoryVideoCard = false
      this.historyVideoStyle = 'z-index:-10;'
    },
    /** 历史视频 -切换选中channel */
    handleChannelClick (id) {
      this.$refs.carTree.toHandlePlayHisVideo(id)
    },
   
    //设置map-当前menu选中菜单
    /**
     * key -当前menu
     * flag1 -map显示对应menu
     * flag2 -tabtable缩放
     */
    setActiveIndex (key, flag1, flag2) {
      this.menuActive = key
      
      if (flag2) {
        this.trailStep = 0
        // this.handleEnlarge(true)
      }
      if (
        this.showhistoryVideoCard === true &&
        this.historyVideoStyle === 'z-index:2000;'
      ) {
        this.carDialogStyle = 'left:30vw'
      } else if (
        this.showRealVideoCard === true &&
        this.realVideoStyle === 'z-index:2000;'
      ) {
        this.carDialogStyle = 'left:30vw'
      } else if (key === '3' && this.trailStep >= 1) {
        this.carDialogStyle = 'left:30vw'
      } else {
        this.carDialogStyle = 'left:75%'
      }
    },
    
  },
  created () {
    this.system = sessionStorage.getItem('system')
  },
  mounted () {
  },
  /** 页面销毁 清除监听 */
  beforeDestroy () {
    clearInterval(this.carDetailTimer)
    this.setRefreshDailogStatus=false;
    // this.$store.dispatch('setRefreshDailogStatus', false)
  }
}
</script>

<style lang="scss" scoped>
.monitoring {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  .mymap-box,
  .expand-tree-box {
    padding: 0 0 15px 0;
  }
  .expand-tree-box {
    position: absolute;
    z-index: 10;
    width: 25vw;
    .carTree {
      padding: 8px;
    }
  }
  .el-table thead th.is-leaf {
    padding: 2px 0 !important;
  }
  .reductionRealVideo {
    position: absolute;
    left: 0;
    z-index: 20;
  }
}
.theme-project-gps {
  .monitoring {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
.theme-project-bus {
  .monitoring {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
